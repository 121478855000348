/***/

@font-face {
  font-family: "InventionBd";
  src: url("../../fontsV2/Invention_Bd.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "InventionLt";
  src: url("../../fontsV2/Invention_Lt.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "InventionLtRg";
  src: url("../../fontsV2/Invention_Rg.ttf");
  font-style: normal;
}

.containerPgrogrammeCampus {
  margin-left: 14%;
  background-color: #e8f2f8;
}
.UlClass {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: white;
  display: flex;
  font-size: 16px;
}

.LIclasses {
  width: 25%;
  font-weight: bold;
  color: #adadad;
  padding-top: 15px;
  /* remove later */
  padding-bottom: 7px;
  text-align: center;
}
.LIclasses a {
  cursor: pointer;
}
.btnActive {
  background-color: #e8f2f8;
  color: #013a51 !important;

  font-family: "InventionBd";
}
.jours3 {
  height: 550px;
}

@media screen and (max-width: 1920px) {
  .containerPgrogrammeCampus {
  }
  .jours3 {
    height: 550px;
  }
  .UlClass {
    font-size: 16px;
  }
}

@media screen and (max-width: 1680px) {
  .containerPgrogrammeCampus {
  }
  .jours3 {
    height: 630px;
  }
  .UlClass {
    font-size: 16px;
  }
}

@media screen and (max-width: 1440px) {
  .UlClass {
    font-size: 12px;
  }

  .jours3 {
    height: 550px;
  }
}

@media screen and (max-width: 1280px) {
  .UlClass {
    font-size: 12px;
  }

  .jours3 {
    /*height: 440px;*/
  }
}
@media screen and (max-width: 900px) {
  .containerPgrogrammeCampus {
    width: 100%;
    height: 620px;
  }
}
