body {
  background-image: url("https://cdn.h8live.com/assets/Biogen/BackgroundBiogen_v2.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.overflow::-webkit-scrollbar-track {
  padding: 2px 0;
  background-color: rgb(209, 209, 209);
  width: 2px;
}

.overflow::-webkit-scrollbar {
  width: 8px;
}

.overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6da537;
}

@font-face {
  font-family: "VoltaBold";
  src: url("../../fonts/VoltaModernDisplay\ 75Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "VoltaRoman";
  src: url("../../fonts/VoltaModernDisplay\ 55Roman.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "VoltaMedium";
  src: url("../../fonts/VoltaModernDisplay\ 65Medium.otf");
  font-style: normal;
}

@font-face {
  font-family: "VoltaItalic";
  src: url("../../fonts/VoltaModernDisplay\ 56Italic.otf");
  font-weight: 400;
  font-style: normal;
}

.BlockErreurBlue {
  background: #00847c;
  width: 600px;
  height: 400px;
  position: fixed;
  top: 30%;
  margin-left: 33%;
  text-align: center;
  color: white;
  font-family: "VoltaMedium";
}
.ContainerV2In1 {
  z-index: 0;
}

.titleWebconference {
  word-spacing: 1.5px;
}

@media screen and (max-width: 1920px) {
  .titleWebconference {
    background-color: #0460a9;
    color: white;
    width: 26%;
    font-family: "VoltaMedium";
    margin-left: 3%;
    position: relative;
    top: 7vh;
    padding-left: 10px;
  }

  .titleweb1,
  .titleweb3 {
    font-size: 15px;
  }

  .titleweb2 {
    font-size: 16px;
    font-family: "VoltaBold";
  }

  .ContainerV2 {
    display: flex;
    margin-left: 3%;
    /*position: relative;*/
    /* top: 22vh;*/
    margin-top: 12%;
  }
  .ContainerV2In1 {
    width: 46%;
  }

  .ContainerV2In2 {
    width: 45%;
    margin-left: 4%;
  }
}

@media screen and (max-width: 1680px) {
  body {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }

  .ContainerV2 {
    margin-top: 14%;
  }
}

@media screen and (max-width: 1440px) {
  .titleweb1,
  .titleweb3 {
    font-size: 13px;
  }

  .titleweb2 {
    font-size: 15px;
    font-family: "VoltaBold";
  }
  .titleWebconference {
    top: 7vh;
    width: 27%;
  }

  .ContainerV2 {
    display: flex;
    margin-left: 3%;
    position: relative;
    margin-top: 16%;
  }
  .ContainerV2In2 {
    width: 50%;
  }
}

.alertPopup {
  position: fixed;
  top: 0;
  left: 37.5%;
  width: 25%;
  height: 12%;
  background-color: #b22222;
  color: white;
  text-align: center;
  border: 1px solid #5e0707;
  z-index: 2;
  padding: 1%;
}

@media screen and (max-width: 1370px) {
  body {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }

  .titleweb1,
  .titleweb3 {
    font-size: 13px;
  }

  .titleweb2 {
    font-size: 13px;
    font-family: "VoltaBold";
  }
  .titleWebconference {
    top: 6vh;
  }
  .alertPopup {
    height: 16%;
  }
  .ContainerV2 {
    display: flex;
    margin-left: 3%;
    position: relative;
    margin-top: 10%;
  }
}

@media screen and (max-width: 1280px) {
  body {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }

  .titleweb1,
  .titleweb3 {
    font-size: 12px;
  }

  .titleweb2 {
    font-size: 12px;
    font-family: "VoltaBold";
  }
  .titleWebconference {
    top: 6vh;
  }

  .ContainerV2 {
    display: flex;
    margin-left: 3%;
    position: relative;
    top: 12vh;
    margin-top: 7%;
  }
  .ContainerV2In2 {
    width: 55%;
  }
}

@media screen and (max-width: 1024px) {
  .titleWebconference {
    margin-left: 4%;
    width: 24%;
    top: 5vh;
  }
}

@media screen and (max-width: 900px) {
  .titleWebconference {
    margin-left: 3%;
    width: 57%;
    top: 1.5%;
    position: absolute;
  }

  .titleweb1,
  .titleweb3 {
    font-size: 9px;
  }

  .titleweb2 {
    font-size: 10px;
    font-family: "VoltaBold";
  }

  .ContainerV2 {
    display: block;

    position: absolute;
  }
  .ContainerV2In1 {
    display: block;
    position: relative;
    width: 100%;
    top: 585px;
  }
  .ContainerV2In2 {
    display: block;

    position: absolute;
    width: 100%;
    top: -8%;
    margin-top: 22%;
  }
  .containerPgrogrammeCampus {
    margin-left: 0% !important;
  }
  .alertPopup {
    width: 80%;
    left: 10%;
    height: 15%;
  }
}
