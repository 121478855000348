.star {
    width:50px;
}
.star:hover {
    cursor: pointer;
}
.starBlock5 {
    vertical-align: top;
    text-align: center;
    display: inline-block;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
}
.starBlock7 {
    vertical-align: top;
    text-align: center;
    display: inline-block;
    width: 14%;
    margin-left: auto;
    margin-right: auto;
}
.RoundUnselect {
    width: 15px;
    height: 15px;
    border-radius: 30px;
    border: 1px solid black;
    display: inline-block;
    margin-left: 17%;
}
.RoundSelect {
    width: 15px;
    height: 15px;
    border-radius: 30px;
    border: 1px solid black;
    background-color: black;
    display: inline-block;
    margin-left: 17%;
}
.validateButtonS {
  border-radius: 5px;
  color: white;
  background-color: #0460a9;
  margin: auto;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
    font-size: large;
    font-weight: bold;
}
.greyBack {
    background-color: #d8d8d8 !important;
}
.greyBack:hover {
    cursor: default !important;
}

.validateButtonS:hover {
    cursor: pointer;
  }