@font-face {
  font-family: "VoltaBold";
  src: url("../../../fonts/VoltaModernDisplay\ 75Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "VoltaRoman";
  src: url("../../../fonts/VoltaModernDisplay\ 55Roman.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "VoltaMedium";
  src: url("../../../fonts/VoltaModernDisplay\ 65Medium.otf");
  font-style: normal;
}

@font-face {
  font-family: "VoltaItalic";
  src: url("../../../fonts/VoltaModernDisplay\ 56Italic.otf");
  font-weight: 400;
  font-style: normal;
}
.CvContainer {
  font-family: Calibri, 'Trebuchet MS', sans-serif;
  position: relative;
  top: 2%;
  padding-left: 8%;
  padding-right: 8%;
  width: 100%;
  overflow: auto;
  height: 440px;

  align-items: center;
}

.orateurBlock {
  margin-bottom: 15px;
  display: inline-block;
}

.nameDescOrateur {
  vertical-align: top;
  color: #0b86c9;
  margin-left: 25px;
  font-size: 17px;
  width: 80%;
}

.descOrateur {
  font-size: 14px;
}
.cvOrateur {
  font-size: 13px;
  line-height: 1.3;
  display: inline-block;
  color: #013a51;
  text-align: justify;
}

textarea {
  width: 100%;
  height: 200px;
}
@media screen and (max-width: 1680px) {
  textarea {
    /*width: 300%;*/
    height: 200px;
  }
}

@media screen and (max-width: 1440px) {
  textarea {
    /*width: 270%;*/
    height: 200px;
  }
}
@media screen and (max-width: 1370px) {
  textarea {
    /*width: 250%;*/
    height: 200px;
  }
}
@media screen and (max-width: 1280px) {
  textarea {
    /*width: 200%;*/
    height: 200px;
  }
}
@media screen and (max-width: 1120px) {
}
@media screen and (max-width: 1080px) {
}
@media screen and (max-width: 900px) {
}
.orateurImage {
  width: 71px;
}
.CvContainer {
  padding-top: 10%;
}
.btnValider {
  font-family: "VoltaBold";
  background-color: #6ba62e; /* Green */
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 10px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 25px;
  width: 60%;
  margin-left: 17%;
  font-weight: bold;
}

.btnValider:hover {
  background-color: #4a7221;
  color: white;
}

.AlertQuestion {
  display: flex;
  justify-content: center;
  background-color: #6ba62e;
  color: white;
  font-family: "VoltaBold";
  width: 30%;
  height: 18%;
  padding-top: 2%;
  position: fixed;
  z-index: 9999;
  top: 30%;
  left: 37%;
}
