@font-face {
  font-family: "InventionBd";
  src: url("../../../fontsV2/Invention_Bd.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "InventionLt";
  src: url("../../../fontsV2/Invention_Lt.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "InventionLtRg";
  src: url("../../../fontsV2/Invention_Rg.ttf");
  font-style: normal;
}

.SessionCampus {
  font-family: "InventionBd";
  position: relative;
  top: 20px;
}

.SessionCampus ul {
  margin-bottom: 3.5%;
}

.SessionCampus li {
  position: relative;
  top: 10px;

  margin-left: 40px;
}

.DateProgramme {
  font-family: "VoltaMedium";
  color: #0460a9;
}

.DateProgrammeHeure {
  font-size: 15px;
  font-family: "VoltaBold";
}

.ProgContainer {
  overflow: scroll;
  height: 100%;
  overflow-x: hidden;
  z-index: -1;
}

.TitreOrateur {
  font-size: 13px;
  position: relative;
  top: -22px;
  margin-right: 20px;
}

@media screen and (max-width: 1680px) {
  .ProgContainer {
    overflow: scroll;
    height: 100%;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 1440px) {
  .SessionCampus li {
    font-size: 12px;
  }
  form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    font-size: 12px;
  }

  .ProgContainer {
    overflow: scroll;
    height: 95%;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 1370px) {
  .SessionCampus li {
    font-size: 11.5px;
  }
  form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    font-size: 11.5px;
  }

  .ProgContainer {
    overflow: scroll;
    height: 100%;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 990px) {
  .SessionCampus li {
    font-size: 11.5px;
  }
  form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    font-size: 11.5px;
  }

  .ProgContainer {
    overflow: scroll;
    height: 100%;
    overflow-x: hidden;
  }

  .TitreOrateur {
    font-size: 13px;
    position: relative;
    top: -22px;
    margin-right: 2px;
  }
}

.ProgTite {
  margin-top: 20px;
  margin-left: 4%;
  font-size: 17px;
  color: #013a51;
}

.BOLD {
  font-family: "InventionBd";
}

.WHITE {
  color: white;
}

.GREEN {
  color: #00857c !important;
}
.BlockProg {
  margin-top: 8px;
  padding-right: 50px;
  margin-bottom: 0.5rem;
}

.Experts {
  color: #706f6f;
}

.blueText {
  color: #0b86c9
}

.greenText {
  color: #6da537;
}

.titleSpan {
  color: #6da537;
  font-weight: lighter;
}