@font-face {
  font-family: "VoltaBold";
  src: url("../../fonts/VoltaModernDisplay\ 75Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "VoltaRoman";
  src: url("../../fonts/VoltaModernDisplay\ 55Roman.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "VoltaMedium";
  src: url("../../fonts/VoltaModernDisplay\ 65Medium.otf");
  font-style: normal;
}

@font-face {
  font-family: "VoltaItalic";
  src: url("../../fonts/VoltaModernDisplay\ 56Italic.otf");
  font-weight: 400;
  font-style: normal;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 25vh;
}

.content {
  font-family: "VoltaRoman";
  padding-left: 15%;
  padding-right: 15%;
  font-size: 19px;
}

.block {
  margin-bottom: 3vh;
}

.borderedBlock {
  border: 1px solid;
  margin-bottom: 3vh;
}

.borderedBlock > * {
  margin-left: 20px;
  margin-right: 20px;
}

.title {
  font-family: "VoltaMedium";
  color: #0460a9;
  text-align: center;
}

.maySubtitle {
  font-family: "VoltaMedium";
  color: #0460a9;
  text-align: right;
}

.partTitle {
  font-family: "VoltaMedium";
  color: #0460a9;
}

.underlineTitle {
  font-family: "VoltaMedium";
  color: #0460a9;
  text-decoration: underline #008cff;
}

.boldText {
  font-family: "VoltaMedium";
  color: #0460a9;
  display: inline-block;
  line-height: 0;
}

.linkText {
  text-decoration: underline #008cff;
  color: #008cff;
  display: inline-block;
  line-height: 0;
}

.underlineText {
  text-decoration: underline #077cdb;
  display: inline-block;
  line-height: 0;
}

ul {
  list-style-type: square;
}

.numberList {
  list-style-type: decimal;
}

.complexList {
  list-style-type: none;
}

.complexList > * {
  margin-top: 10px;
  margin-bottom: 10px;
}